// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-templates-404-tsx": () => import("./../../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-careers-opening-tsx": () => import("./../../../src/templates/careers/opening.tsx" /* webpackChunkName: "component---src-templates-careers-opening-tsx" */),
  "component---src-templates-careers-openings-tsx": () => import("./../../../src/templates/careers/openings.tsx" /* webpackChunkName: "component---src-templates-careers-openings-tsx" */),
  "component---src-templates-contact-us-tsx": () => import("./../../../src/templates/contact-us.tsx" /* webpackChunkName: "component---src-templates-contact-us-tsx" */),
  "component---src-templates-crime-index-tsx": () => import("./../../../src/templates/crime-index.tsx" /* webpackChunkName: "component---src-templates-crime-index-tsx" */),
  "component---src-templates-employment-screening-tsx": () => import("./../../../src/templates/employment-screening.tsx" /* webpackChunkName: "component---src-templates-employment-screening-tsx" */),
  "component---src-templates-find-a-risk-advisor-director-tsx": () => import("./../../../src/templates/find-a-risk-advisor/director.tsx" /* webpackChunkName: "component---src-templates-find-a-risk-advisor-director-tsx" */),
  "component---src-templates-find-a-risk-advisor-overview-tsx": () => import("./../../../src/templates/find-a-risk-advisor/overview.tsx" /* webpackChunkName: "component---src-templates-find-a-risk-advisor-overview-tsx" */),
  "component---src-templates-gam-tsx": () => import("./../../../src/templates/gam.tsx" /* webpackChunkName: "component---src-templates-gam-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-in-the-news-post-tsx": () => import("./../../../src/templates/in-the-news/post.tsx" /* webpackChunkName: "component---src-templates-in-the-news-post-tsx" */),
  "component---src-templates-india-post-tsx": () => import("./../../../src/templates/india/post.tsx" /* webpackChunkName: "component---src-templates-india-post-tsx" */),
  "component---src-templates-industry-tsx": () => import("./../../../src/templates/industry.tsx" /* webpackChunkName: "component---src-templates-industry-tsx" */),
  "component---src-templates-legal-post-tsx": () => import("./../../../src/templates/legal/post.tsx" /* webpackChunkName: "component---src-templates-legal-post-tsx" */),
  "component---src-templates-on-demand-webinar-tsx": () => import("./../../../src/templates/on-demand-webinar.tsx" /* webpackChunkName: "component---src-templates-on-demand-webinar-tsx" */),
  "component---src-templates-our-approach-tsx": () => import("./../../../src/templates/our-approach.tsx" /* webpackChunkName: "component---src-templates-our-approach-tsx" */),
  "component---src-templates-our-insights-analysis-tsx": () => import("./../../../src/templates/our-insights/analysis.tsx" /* webpackChunkName: "component---src-templates-our-insights-analysis-tsx" */),
  "component---src-templates-our-insights-blog-tsx": () => import("./../../../src/templates/our-insights/blog.tsx" /* webpackChunkName: "component---src-templates-our-insights-blog-tsx" */),
  "component---src-templates-our-insights-blogs-tsx": () => import("./../../../src/templates/our-insights/blogs.tsx" /* webpackChunkName: "component---src-templates-our-insights-blogs-tsx" */),
  "component---src-templates-our-insights-overview-tsx": () => import("./../../../src/templates/our-insights/overview.tsx" /* webpackChunkName: "component---src-templates-our-insights-overview-tsx" */),
  "component---src-templates-our-insights-report-tsx": () => import("./../../../src/templates/our-insights/report.tsx" /* webpackChunkName: "component---src-templates-our-insights-report-tsx" */),
  "component---src-templates-our-insights-reports-tsx": () => import("./../../../src/templates/our-insights/reports.tsx" /* webpackChunkName: "component---src-templates-our-insights-reports-tsx" */),
  "component---src-templates-our-insights-risk-snapshot-tsx": () => import("./../../../src/templates/our-insights/risk-snapshot.tsx" /* webpackChunkName: "component---src-templates-our-insights-risk-snapshot-tsx" */),
  "component---src-templates-our-insights-risk-snapshots-tsx": () => import("./../../../src/templates/our-insights/risk-snapshots.tsx" /* webpackChunkName: "component---src-templates-our-insights-risk-snapshots-tsx" */),
  "component---src-templates-our-insights-video-tsx": () => import("./../../../src/templates/our-insights/video.tsx" /* webpackChunkName: "component---src-templates-our-insights-video-tsx" */),
  "component---src-templates-our-insights-videos-tsx": () => import("./../../../src/templates/our-insights/videos.tsx" /* webpackChunkName: "component---src-templates-our-insights-videos-tsx" */),
  "component---src-templates-our-story-post-tsx": () => import("./../../../src/templates/our-story/post.tsx" /* webpackChunkName: "component---src-templates-our-story-post-tsx" */),
  "component---src-templates-overview-list-tsx": () => import("./../../../src/templates/overview/list.tsx" /* webpackChunkName: "component---src-templates-overview-list-tsx" */),
  "component---src-templates-overview-overview-tsx": () => import("./../../../src/templates/overview/overview.tsx" /* webpackChunkName: "component---src-templates-overview-overview-tsx" */),
  "component---src-templates-pinkerton-locations-tsx": () => import("./../../../src/templates/pinkerton-locations.tsx" /* webpackChunkName: "component---src-templates-pinkerton-locations-tsx" */),
  "component---src-templates-press-room-overview-tsx": () => import("./../../../src/templates/press-room/overview.tsx" /* webpackChunkName: "component---src-templates-press-room-overview-tsx" */),
  "component---src-templates-press-room-post-tsx": () => import("./../../../src/templates/press-room/post.tsx" /* webpackChunkName: "component---src-templates-press-room-post-tsx" */),
  "component---src-templates-search-tsx": () => import("./../../../src/templates/search.tsx" /* webpackChunkName: "component---src-templates-search-tsx" */),
  "component---src-templates-services-list-tsx": () => import("./../../../src/templates/services/list.tsx" /* webpackChunkName: "component---src-templates-services-list-tsx" */),
  "component---src-templates-services-service-tsx": () => import("./../../../src/templates/services/service.tsx" /* webpackChunkName: "component---src-templates-services-service-tsx" */),
  "component---src-templates-tags-tsx": () => import("./../../../src/templates/tags.tsx" /* webpackChunkName: "component---src-templates-tags-tsx" */)
}

